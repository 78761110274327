'use client'
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog"
import React, { useEffect, useState } from 'react'
import { Button } from '../ui/button'
import { Textarea } from "../ui/textarea";
import { sendFeedback } from "@/server/server-actions";
import { useToast } from "@/hooks/use-toast";
import { useSessionStorageContext } from "@/contexts/SessionStorageContextProvider";
import { FaStar } from "react-icons/fa";
import { cn } from "@/lib/utils";

export default function FeedbackModal() {
    const [openModal, setOpenModal] = useState(false);
    const formRef = React.useRef<HTMLFormElement>(null);
    const [formErrors, setFormErrors] = useState<string[]>([]);
    const [isFormSubmitting, startTransition] = React.useTransition();
    const [sessionStorage, setSessionStorage] = useSessionStorageContext()

    const { toast } = useToast()

    useEffect(() => {
        if (sessionStorage.numImagesGenerated >= 3 && !sessionStorage.isFeedbackSubmitted && sessionStorage.isGeneratingImage) {
            const t = setTimeout(() => { setOpenModal(true) }, 1000)
            return () => clearTimeout(t)
        }
    }, [sessionStorage.numImagesGenerated, sessionStorage.isGeneratingImage])

    const handleSubmit = () => {
        if (!formRef.current) return

        const formData = new FormData(formRef.current)
        const jsonFormData = Object.fromEntries(formData)

        startTransition(async () => {
            const r = await sendFeedback(jsonFormData)
            closeModal()
            setSessionStorage({ ...sessionStorage, isFeedbackSubmitted: true })
            if (r)
                toast({
                    description: "Thank you for your feedback! :)",
                    duration: 2000
                })
            else
                toast({
                    description: "Uh Oh! Something went wrong.",
                    duration: 2000,
                    variant: 'destructive'
                })
        })
    }
    const closeModal = () => {
        setOpenModal(false)
    }

    return (<>
        <Dialog open={openModal} onOpenChange={setOpenModal}>
            <DialogContent className="z-[999]" onInteractOutside={(event) => event.preventDefault()}>
                <DialogHeader>
                    <DialogTitle>Help us out!</DialogTitle>
                </DialogHeader>
                <div className="flex justify-center">
                    <form className="w-full" ref={formRef}>
                        <h2 className="text-sm sm:text-base font-semibold">
                            How was the overall experience?
                        </h2>
                        <RatingFormComponent name="overallExperience" formErrors={formErrors} setFormErrors={setFormErrors} disabled={isFormSubmitting} />

                        <h2 className="text-sm sm:text-base mt-3 font-semibold">
                            Do you like the idea of creating your own products with AI?
                        </h2>
                        <RatingFormComponent name="concept" formErrors={formErrors} setFormErrors={setFormErrors} disabled={isFormSubmitting} />

                        <h2 className="text-sm sm:text-base mt-3 font-semibold">
                            How were the designs our model generated?
                        </h2>
                        <RatingFormComponent name="designs" formErrors={formErrors} setFormErrors={setFormErrors} disabled={isFormSubmitting} />

                        <h2 className="text-sm sm:text-base mt-3 font-semibold">
                            Anything else you&lsquo;d like to share?
                        </h2>
                        <Textarea placeholder="Write your feedback here" name="write" disabled={isFormSubmitting} />
                    </form>
                </div>
                <DialogFooter className="pb-2 grid grid-cols-2 gap-2">
                    <Button variant="outline" onClick={closeModal} className="w-full">
                        Later
                    </Button>
                    <Button variant='default' type="submit" onClick={handleSubmit} className="w-full" disabled={formErrors.length > 0 || isFormSubmitting}>
                        {isFormSubmitting && <span className="loading loading-spinner loading-xs me-2" />}
                        Submit
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    </>
    )
}

type RatingFormComponentProps = {
    name: string
    formErrors: string[]
    setFormErrors: (errors: string[]) => void
    disabled?: boolean
}

export const RatingFormComponent = (props: RatingFormComponentProps) => {
    const [rating, setRating] = useState(0);  // Stores the selected rating
    const [hoverRating, setHoverRating] = useState(0);  // Stores the rating on hover

    useEffect(() => {
        if (rating == 0) {
            props.setFormErrors([...props.formErrors, props.name])
        }
        else {
            props.setFormErrors(props.formErrors.filter(x => x != props.name))
        }
    }, [rating == 0])

    const handleRatingHover = (e: React.MouseEvent<SVGSVGElement>) => {
        if (props.disabled) return
        const value = parseInt(e.currentTarget.dataset.value as string, 10);
        setHoverRating(value);
    }

    const handleRatingClick = (e: React.MouseEvent<SVGSVGElement>) => {
        if (props.disabled) return
        const value = parseInt(e.currentTarget.dataset.value as string, 10);
        setRating(value);
    }
    const handleRatingLeave = () => {
        if (props.disabled) return
        setHoverRating(0)
    }

    return (
        <div className="w-fit flex cursor-pointer" onMouseLeave={handleRatingLeave}>
            {[1, 2, 3, 4, 5].map((value) => (
                <FaStar
                    key={value}
                    data-value={value}
                    onMouseEnter={handleRatingHover}
                    onClick={handleRatingClick}
                    className={cn("hover:scale-125 me-0.5 transition-all text-slate-300 size-10", {
                        "text-slate-700": hoverRating ? hoverRating >= value : rating >= value
                    })}
                />
            ))}
            <input type="hidden" value={rating} name={props.name} />
        </div>
    );
};
